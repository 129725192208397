import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const J3Mz = lazy(() => import('./routes/J3Mz'));
const RbX8 = lazy(() => import('./routes/RbX8'));

// const boards = [`rbX8, J3Mz`];

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/J3Mz" element={<J3Mz />} />
          <Route path="/rbX8" element={<RbX8 />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
